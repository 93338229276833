<template>
  <div id="editCouponDialog">
    <el-dialog
      :title="planId ? '编辑发券计划' : '新增发券计划'"
      :visible.sync="show"
      :close-on-press-escape="false"
      @closed="reset"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="140px"
      >
        <el-form-item label="优惠券名称" prop="couponName">
          <el-input
            v-model="addForm.couponName"
            placeholder="请输入优惠券名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="优惠券类型" prop="couponType">
          <el-select
            v-model="addForm.couponType"
            placeholder="请选择优惠券类型"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item of select_couponType"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            v-if="addForm.couponType === '按时间'"
            label="前多少小时可免"
        >
          <el-input
              v-model.number="addForm.couponHours"
              placeholder="请输入前多少小时可免"
          ></el-input>
        </el-form-item>
        <el-form-item label="优惠券金额" prop="couponValue" v-if="addForm.couponType==='按金额'">
          <el-input
            v-model.number="addForm.couponValue"
            placeholder="请输入优惠券金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="发放频率" prop="releaseType">
          <el-select
              v-model="addForm.releaseType"
              placeholder="请选择发放频率"
          >
            <el-option
                :label="item.label"
                :value="item.value"
                v-for="item of select_durationType"
                :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="过期天数" prop="expireDays">
          <el-input
            v-model.number="addForm.expireDays"
            placeholder="请输入过期天数"
          ></el-input>
        </el-form-item>
        <el-form-item label="最多可领" prop="maxIssue">
          <el-input
            v-model.number="addForm.maxIssue"
            placeholder="请输入最多可领"
          ></el-input>
        </el-form-item>
        <el-form-item label="发放数量" prop="releaseNum">
          <el-input
            v-model.number="addForm.releaseNum"
            placeholder="请输入最多可领"
          ></el-input>
        </el-form-item>
        <el-form-item label="周期清零" prop="renewable">
          <el-radio-group v-model="addForm.renewable">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      planId: "",
      addForm: {
        couponName: "",
        couponType: "",
        couponValue: "",
        expireDays: "",
        maxIssue: "",
        couponHours: "",
        releaseNum: "",
        releaseType: "",
        renewable: "",
      },
      addFormRules: {
        couponName: [
          { required: true, message: "请输入优惠券名称", trigger: "blur" },
        ],
        couponType: [
          { required: true, message: "请选择优惠券类型", trigger: "change" },
        ],
        releaseType: [
          { required: true, message: "请选择发放频率", trigger: "change" },
        ],
        renewable: [
          { required: true, message: "请选择下个周期是否可续期", trigger: "change" },
        ],
        couponValue: [
          { type: "number", message: "优惠券金额必须为数字值" },
        ],
        expireDays: [
          { required: true, message: "请输入过期天数", trigger: "blur" },
          { type: "number", message: "过期天数必须为数字值" },
        ],
        maxIssue: [
          { required: true, message: "请输入最多可领", trigger: "blur" },
          { type: "number", message: "最多可领必须为数字值" },
        ],
        releaseNum: [
          { required: true, message: "请输入发放数量", trigger: "blur" },
          { type: "number", message: "发放数量必须为数字值" },
        ],
        couponHours: [
          { type: "number", message: "小时必须为数字值" },
        ],
        number: [{ type: "number", message: "必须为数字值" }],
      },
    };
  },
  methods: {
    reset() {
      this.planId = "";
      this.$set(this, "addForm", {
        couponName: "",
        couponType: "",
        couponValue: "",
        expireDays: "",
        maxIssue: "",
        couponHours: "",
        releaseNum: "",
        releaseType: "",
        renewable: "",
      });
      this.$refs["addForm"].resetFields();
    },
    async confirmBtn() {
      let falg = true;
      this.$refs["addForm"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      try {
        this.loading = true;
        let url = "/coupon/plan/add";
        let params = {
          ...this.addForm,
          couponHours:this.addForm.couponHours?this.addForm.couponHours:null,
          couponValue:this.addForm.couponValue?this.addForm.couponValue:null,
          merchantId: this.$route.params.id,
        };
        if (this.planId) {
          url = "/coupon/plan/edit"
          params.planId = this.planId
          delete params.merchantId
        }
        let res = await this.$http.post(url, params);
        if (res.code === 0) {
          this.getList();
          this.$message.success("操作成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#editCouponDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
